import React from 'react'
import MissionCards from './cards/MissionCards'
import { OUR_MISSION_CARDS } from '../constants/missionCards'

function HeritageContainer() {
    return (
        <section className="gs__heritage py-5">
            <div className='container gs__haritage-container'>
                <div className="row justify-content-center">
                    <div className=" col-12 col-md-12">
                        <div className="content-container">
                            <div className="ttile-container">
                                <h1 className='title mb-3'>Protect  <span>India’s</span> Heritage!</h1>
                            </div>
                            <div className="subtitle-cotainer">
                                <h2 className='subtitle mb-5'>Support Shree Geeta Gaushala’s Mission</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 gs__mission-cards-container">
                        {OUR_MISSION_CARDS.map((card, index) => (
                        <MissionCards key={index} {...card} />
                        ))}
                       
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeritageContainer