import React from 'react'
import { assets } from '../../assets/asset'

function ActivitesCard({title, description,image}) {
    const getLimitedDescription = (text) => {
        if (!text) return null;
        return text.length > 250 ? text.slice(0, 250) + "..." : text;
      };
    
      const limitedDescription = getLimitedDescription(description);
    return (
        <>
            <div className="card card__activities">
                <img src={image} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h2 className='card-heading'>{title}</h2>
                    <p className="card-text"> {limitedDescription}</p>
                </div>

                <div className="card-body">
                    <button className='btn activity-read-more-btn mt-3'> Read More</button>
                </div>

            </div>
        </>
    )
}

export default ActivitesCard