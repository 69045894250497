export const OUR_MISSION_CARDS = [
    {
        title : "Fodder Donation",
        description: "Provide nourishment to our cows and bulls by contributing fodder. Choose the type of fodder you'd like to donate and make a difference in their daily care."
    },
    {
        title: "Monthly Care",
        description: "Help us provide a safe and loving environment for abandoned and needy cows and bulls. By sponsoring a gauvansh (cow or bull), you ensure they live a happy, dignified life in our shelter."
    },
    {
        title: "Medical Support",
        description: "Many of the rescued cows and bulls in our care are elderly or in poor health. Your contribution toward medical supplies helps us treat them and ensure they live comfortably."
    },
    {
        title: "Gaudaan (Cow Donation)",
        description: "Participate in the noble act of donating a desi cow. The cow’s milk will support saints and spiritual practitioners during its productive years. Once the cow retires, she will receive lifetime care at our Gaushala."
    },
    {
        title: "Food Distribution",
        description: "Join us in feeding the hungry. Contribute to our food distribution programs to bring meals to children and individuals in need."
    },
    {
        title: "Adopt a Cow",
        description: "Adopt a cow and make her part of your extended family. Stay updated on her health, milking, and calving. You can visit her in person or connect through video calls to ensure her well-being."
    },
]