import React from 'react'
import LanguageDropdown from './LanguageDropdown';
import { assets } from '../assets/asset';
function HeaderStrip() {
    return (
        <>
            <div className="container-fluid header__strip">
                <div className="wrapper-container d-flex align-center ">
                    <div className="quote">
                        <h1>ॐ जय श्री गॏमाताय शरणम् प्रपधेः |</h1>
                    </div>
                    <div className="btn-container-header-strip d-flex align-items-center -justify-content-center ms-auto gap-2">
                        <LanguageDropdown />
                        <button className='btn btn-secondary'><img src={assets.icons.phoneIconVar2} alt="phone" width={15}/>+91 9435090285</button>
                    </div>
                </div>
            </div >
        </>
    )
}

export default HeaderStrip;