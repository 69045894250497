import React from "react";
import "./Term&Conditions.css";
import ContactUsBanner from "../../components/about-us-page/banner/ContactUsBanner/ContactUsBanner";
function TermAndCondition() {
  return (
    <>
    <ContactUsBanner title={"Terms & Conditions"}/>
      <div class="terms-and-conditions">
        <div className="head">
          <h1>Terms and Conditions</h1>
        </div>
        <div className="head-description">
          <h2>1. Introduction</h2>
          <p>
            This document sets out the terms and conditions on which you may use
            the content on {" "} 
            <a href="https://shreegeetagaushala.org">
               https://shreegeetagaushala.org
            </a>{" "}
            website, the mobile browser site, Shree Geeta Gaushala's in-store
            applications, and other digital publishing services owned by Shree
            Geeta Gaushala Parmarth Sanstha. All services herein will be
            referred to as Shree Geeta Gaushala Content Services.
          </p>
        </div>

        <div className="head-description">
          <h2>2. Registration Access and Use</h2>
          <p>
            We welcome users to register on our digital platforms. We offer the
            following registration services, which may be subject to change in
            the future. All changes will be appended to the terms and conditions
            page and communicated to existing users by email. Registration
            services are offered for individual subscribers only. If multiple
            individuals propose to access the same account or for corporate
            accounts, kindly contact us. Subscription rates will vary for
            multiple same-time access. The nature and volume of Shree Geeta
            Gaushala content services you consume on the digital platforms will
            vary according to the type of registration you choose, your
            geography, or the offer you subscribe to:
          </p>
          <ul>
            <li>Free Registration</li>
            <li>Premium Registration</li>
            <li>Special Offers</li>
            <li>Combo Registrations with Partners</li>
          </ul>
          <p>
            The details of the services and access offered for each account have
            been listed on{" "}
            <a href="https://shreegeetagaushala.org">
              https://shreegeetagaushala.org
            </a>
            . In exceptional circumstances, we may cease to provide subscription
            services. We will give you at least 7 days' notice, if possible. If
            we do so, we will have no further obligation to you.
          </p>
        </div>

        <div className="head-description head-remove-list-style">
          <h2>3. Privacy Policy and Registration</h2>
          <p>
            All information received by us from your registration on
            shreegeetagaushala.org or other digital products of Shree Geeta
            Gaushala will be used in accordance with our Privacy Policy. Kindly
            read the details mentioned below. Upon registration, we expect you
            to provide Shree Geeta Gaushala with accurate and complete
            information for the compulsory fields. We also expect you to keep
            the information secure, specifically your access passwords and
            payment information. Kindly update your information periodically to
            keep your account relevant. Shree Geeta Gaushala will rely on the
            information you provide.
          </p>
          <p>
            Each registration is for a single user only. Upon registration, you
            will choose a username and password ("ID"). You are not allowed to
            share your ID or give access to your account to anyone else. Shree
            Geeta Gaushala Premium subscription does not allow multiple users on
            a network or within an organization to use the same ID. If we
            discover that you are sharing your personal access, Shree Geeta
            Gaushala may cancel or suspend your access to premium services
            without further obligation to you.
          </p>

          <p>
            You are responsible for all use of shreegeetagaushala.org premium
            service made by you or anyone else using your ID. If you believe
            there has been a breach of security such as the disclosure, theft,
            or unauthorized use of your ID or any payment information, you must
            notify Shree Geeta Gaushala immediately. We recommend that you do
            not select an obvious password (such as your name) and that you
            change it regularly.
          </p>
          <p>
            If you provide Shree Geeta Gaushala with an email address that will
            result in any messages we send being delivered via a network or
            device operated or owned by a third party (e.g., your employer or
            college), you promise that you are entitled to receive those
            messages. To ensure emails land in your inbox, you will add the
            Shree Geeta Gaushala receipt ID to your safe list.
          </p>
          <ul>
            Important - Kindly Note:
            <li>
              (A) When you subscribe to joint content offers with partners, your
              email ID, access password, and personal information will be shared
              with the participating content partner brand via an encrypted
              server-to-server protocol. This sharing facilitates seamless
              access to the partner brand’s platform. Specific terms and privacy
              policies of the partner brand will apply.
            </li>
            <li>
              (B) Joint offers and special price offers are generally limited to
              new users on partner sites. Existing subscribers are not eligible
              for clubbed offers. You are advised to study the offer details
              before subscribing.
            </li>
            <li>
              (C) When subscribing to joint offers supported by non-content
              brands, your personal information may be shared with the concerned
              brand for promotional purposes, governed by their privacy policy
              and terms.
            </li>
          </ul>
          <p>
            As a registered user or subscriber, you may choose to use the
            "Remember Me" login feature, enabling automatic login without manual
            entry each time. We recommend not enabling this feature on public or
            shared devices to prevent unauthorized access.
          </p>
        </div>

        <div className="head-description">
          <h2>4) Personal Subscription Services</h2>
          <p>
            Personal subscription services include Shree Geeta Gaushala premium
            access to exclusive content. When subscribing to premium access, you
            gain access to special features curated by the editors. The nature
            of the content behind the paywall is subject to change.
          </p>
          <p>
            <span>Types of subscription:</span> Services and pricing may vary
            depending on your location and device. Subscription packages and
            prices are subject to change.
          </p>
          <p>
            <span>Contract formation:</span> Shree Geeta Gaushala will process
            your subscription promptly but does not guarantee activation within
            a specified time. By submitting your payment, you make an offer to
            purchase a subscription. A contract is formed once we verify payment
            details and email address. We may reject offers at our discretion.
          </p>
          <p>
            <span>Payment details</span>You must provide accurate payment
            information when subscribing. If payment authorization is canceled
            or fails, we may suspend your access. In cases of refunds, we will
            credit the card or payment method used.
          </p>
          <p>
            <span>Pricing:</span>The subscription price will be clearly
            displayed during the sign-up process. We will notify you of any
            price increases in advance and offer the option to cancel. Discounts
            are determined at the time of subscription.
          </p>
          <p>
            <span>Taxes: </span>Subscription and access services are subject to
            applicable taxes, including Service Tax or VAT, based on your
            location.
          </p>
          <p>
            <span>Pricing errors:</span>In the event of a pricing error, Shree
            Geeta Gaushala reserves the right to correct the mistake and offer
            the option to cancel or proceed at the correct price.
          </p>
          <p>
            <span>Other costs:</span>You are responsible for any internet or
            telecommunications charges incurred while accessing premium
            services.
          </p>
        </div>
        <div className="head-description list-style-dot">
          <h2>
            5) Subscription Period, Renewal and Cancellation of Personal
            Subscriptions
          </h2>
          <ul>
            <li>
              <span>Renewals:</span>If you choose to pay monthly, your
              subscription will continue until you inform us that you no longer
              wish to receive it, in which case you will stop paying the monthly
              fees. We will notify you at least 7 days in advance of any changes
              to the price of your subscription that will apply upon the next
              monthly renewal. Please visit the "How to cancel" section below
              for details on how to cancel your monthly subscription. Unless you
              notify us before the end of your chosen subscription period that
              you no longer wish to continue, your subscription will renew for
              another period as applicable. We will charge the subscription
              using the same card or other payment method that you previously
              used.
            </li>
            <li>
              <span>Cancellation Policy for Digital Subscriptions:</span>By
              placing your order, you agree that we may start your subscription
              immediately upon our accepting your order. This means that you are
              not entitled to a refund if you change your mind after we have
              provided you with access to your subscription. Except as set out
              in the previous section, you do not have any right to cancel your
              subscription or any part of it until the end of your current
              subscription period. Although you may notify us of your intention
              to cancel at any time, such notice will only take effect at the
              end of your current subscription period, and you will not receive
              a refund.
            </li>

            <li>
              <span>How to Cancel?</span>You may notify us of your wish to
              cancel your subscription by contacting our Customer Services team.
              To enable us to cancel your subscription, please provide us with
              your complete name (as provided on the subscription order form),
              email address, and contact number clearly written.
            </li>
            <li>
              <span>Cancellation by Us</span>Shree Geeta Gaushala Parmarth
              Sanstha reserves the right to suspend or terminate your
              subscription if you breach these terms and conditions, with or
              without notice and without further obligation to you. We may also
              suspend or terminate your subscription if we are prevented from
              providing services due to circumstances beyond our control. If we
              terminate your subscription for any reason and/or permanently
              cease providing services, then, unless there are exceptional
              circumstances, we will provide you with a pro-rata refund to your
              credit card. This means we will refund you for any amounts that
              you have paid us in advance that relate to any remaining and
              unexpired period of your subscription.
            </li>
            <li>
              <span>Cancellation of Your Registration:</span>If you are a
              registered user but not a subscriber, Shree Geeta Gaushala
              Parmarth Sanstha reserves the right to suspend or terminate your
              registration at any time, with or without notice and without
              further obligation to you. If you would like to cancel your
              registration, please contact our Customer Services team.
            </li>
          </ul>
        </div>
        <div className="head-description">
          <h2>6) Who Your Personal Subscription Contract is with</h2>
          <p>
            If you buy a subscription and reside in any country, you will be
            contracting with Shree Geeta Gaushala Parmarth Sanstha,Assam Trunk Rd, 
            Jorhat, Assam 785010.
          </p>
        </div>
        <div className="head-description">
          <h2>7) User Generated Content</h2>
          <h5>
            Shreegeetagaushala.org includes comments sections, blogs, and other
            interactive features that allow interaction between users and
            between users and Shree Geeta Gaushala Parmarth Sanstha. We refer to
            information posted or contributed by users as "User Generated
            Content" or "UGC." If you wish to view or participate through UGC,
            you must comply with specific rules and guidelines. We also
            recommend that you read our Comments Guidelines, which provide
            further guidance on commenting on Shreegeetagaushala.org. You are
            responsible for the content of any UGC that you or we publish. You
            are financially responsible for any claim against us by any third
            party that your UGC is not in accordance with the UGC policy. You
            further agree to:
          </h5>
          <ul>
            <li>Only publish UGC that is your original content and does not infringe the copyright or other rights of any third party.</li>
            <li>Not post, link to, or otherwise publish any UGC containing advertising or promotion for goods and services, spam, or other forms of unsolicited communication.</li>
            <li>Not post, link to, or otherwise publish any UGC with recommendations to buy or not buy a particular share or other investment, or which contains confidential information of another party or affects the price or value of any share or investment.</li>
            <li>Not post, link to, or otherwise publish UGC that is threatening, offensive, libellous, indecent, or otherwise unlawful.</li>
            <li>Not post discriminatory comments based on race, religion, sex, gender, sexual orientation, disability, or age.</li>
            <li>Respectfully challenge different points of view without attacking other commentators.</li>
            <li>Not disguise the origin of any UGC, impersonate any person or entity, or misrepresent any connection with any person or entity.</li>
            <li>Not post or publish any UGC unrelated to the forum or its topic.</li>
            <li>Not post or transmit any UGC containing viruses, files, or code designed to interrupt, destroy, or limit the functionality of Shreegeetagaushala.org or any computer software or equipment.</li>
            <li>Not collect or store other users' personal data.</li>
            <li>Not restrict or inhibit any other user from using the forums.</li>
            <li>Comply with our Commenting FAQ guidelines. <p>Shree Geeta Gaushala Parmarth Sanstha cannot fully monitor all UGC published on shreegeetagaushala.org. However, where we receive notice of UGC that is misleading, untrue, offensive, unlawful, or otherwise in breach of these terms and conditions, we will review the UGC, decide whether to remove it, and act accordingly. This may include banning users from participating in UGC on shreegeetagaushala.org. If you believe any UGC published on shreegeetagaushala.org infringes your legal rights or is not allowed under these terms and conditions, please notify us immediately with specific details by contacting us.</p></li>
          </ul>
        </div>
        <div className="head-description">
            <h2>8) Third-Party Sites and Services</h2>
            <p>Shreegeetagaushala.org may contain links to other Internet websites or online and mobile services provided by independent third parties, including websites of advertisers and sponsors ("Third Party Sites"). It is your decision whether you purchase or use any third-party products or services made available on or via Third Party Sites, and you should read Section 9 carefully. Our Privacy Policy does not apply to Third Party Sites. Advertisers and sponsors are responsible for ensuring that material submitted for inclusion on shreegeetagaushala.org complies with international and national law. Shree Geeta Gaushala Parmarth Sanstha is not responsible for any error, inaccuracy, or incorrect links in advertising or sponsorship material.</p>
        </div>
        <div className="head-description list-style-disc">
            <h2>9) Our Responsibilities Towards You</h2>
            <ul>
                <li><span>Limitations of Content Published on Shreegeetagaushala.org:</span>All content published on shreegeetagaushala.org is for general information and educational purposes and is not intended to address your specific requirements. Content does not constitute advice or recommendations. Independent advice should be sought before making decisions, including financial ones.
                </li>
                <li><span>What We Promise:</span>Shree Geeta Gaushala Parmarth Sanstha promises to operate shreegeetagaushala.org with reasonable skill and care and will use reasonable efforts to remedy faults promptly.</li>
                <li><span> What We Do Not Promise:</span>Content is provided "as is" and "as available." We do not make any promises regarding the completeness, accuracy, or availability of content or services on shreegeetagaushala.org. We are not liable for matters beyond our control, including third-party communications networks and acts of nature.</li>
            </ul>
        </div>
        <div className="head-description">
            <h2>10) Choice of Law and Jurisdiction</h2>
            <p>If you are a user whose principal address or use of Shree Geeta Gaushala Parmarth Sanstha's digital services occurs anywhere globally, these terms will be subject to Indian law. Both you and we agree that the competent courts in New Delhi, India, will have non-exclusive jurisdiction over any disputes.</p>
        </div>
        <div className="head-description list-style-disc">
            <h2>11) General</h2>
          <li>You may not license or transfer any of your rights under these terms and conditions. We may transfer any of our rights or obligations under these terms and conditions to any individual, organization or entity but if we do so we will ensure that any company/individual/entity to whom we transfer our rights or obligations will continue to honour your rights under them. Any resultant changes to the terms and conditions will be intimated to you via email and updated on this website. If any provision of these terms and conditions is found to be invalid by any court having competent jurisdiction, the invalidity of that provision will not affect the validity of the remaining provisions of these terms and conditions, which will remain in full force and effect. Failure by either party to exercise any right or remedy under these terms and conditions does not constitute a waiver of that right or remedy. Headings in these terms and conditions are for convenience only and will have no legal meaning or effect. These terms and conditions constitute the entire agreement between you and Business Standard Private Limited for your use of the Business Standard website, Business Standard mobile and other digital products and services from Business Standard. They supersede all previous communications, representations and arrangements, either written or oral.</li>
        </div>
        <div className="head-description">
            <h2>12) Content ownership
            </h2>
            <p>• All Content created and published on the digital platforms under the url <a href="https://shreegeetagaushala.org"> https://shreegeetagaushala.org</a> the mobile browser site, applications, Business Standard E-paper belong to Business Standard Private Limited and its licensors who own all intellectual property rights (including copyright and database rights) No intellectual property rights in any of the content are transferred to you while you consume the content on this platform. "BS" and "Business Standard" are registered trade marks of Business Standard Private Limited and you may not use them without prior written permission from Business Standard. You are permitted to use the content on this platform only as set out in our Copyright Policy.</p>
        </div>
        <div className="head-description">
          <h2>13. Changes to Terms and Conditions and Validity</h2>
          <p>
            These terms and conditions were published on 18th Oct 2024 and
            replace with immediate effect the terms and conditions previously
            published.
          </p>
        </div>
      </div>
    </>
  );
}

export default TermAndCondition;
