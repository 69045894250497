import React from "react";
import ContactUsCard from "./ContactUsCard";

const contacts = [
    {
      id: 1,
      phone: "+91 9435090285",
      email: "Shreegeetagaushala@gmail.com",
      address: "shree geeta gaushala, Assam Trunk Rd, Jorhat, Assam 785010",
    },
  ];
  
function ContactUsCardContainer(){
return(
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {contacts.map(contact => (
        <ContactUsCard
          key={contact.id}
          phone={contact.phone}
          email={contact.email}
          address={contact.address}
        />
      ))}
      
    </div>
)
}
export default ContactUsCardContainer;